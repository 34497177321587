<template>
  <BaseCard class="searchable-table-card" padding="0">
    <div class="searchable-table-card__header">
      <div class="title">{{ headerLabel }}</div>
      <Button
        v-if="headerBtn"
        style="padding: 5px 15px"
        :variant="headerBtn.variant"
        :label="headerBtn.label"
        @click="headerBtn.action"
      />
    </div>

    <div class="searchable-table-card__body">
      <FormulateInput
        type="search"
        name="search"
        class="custom-search-left"
        :placeholder="searchPlaceholder"
        @input="searchUpdated"
      />
      <div class="tabel-list">
        <div
          class="tabel-list__item"
          v-for="(item, idx) in searchQuery"
          :key="idx"
          :data="item"
        >
          <div class="wrapper">
            <div class="label">{{ item.label }}</div>

            <router-link
              v-for="(action, actionIdx) in item.actions"
              :key="`link-${actionIdx}-${item._id}`"
              :to="action.link ? action.link : ''"
              target="_blank"
              class="link"
            >
              <font-awesome-icon
                :style="action && item.style ? action.style : ''"
                :icon="action.icon"
                :key="`icon-link-${actionIdx}-${item._id}`"
                @click="action.action ? action.action(item._id) : () => {}"
              />
            </router-link>

            <div class="status" v-if="item.status !== undefined">
              {{ item.status }}
            </div>

            <div v-if="sortable" class="sorting">
              <font-awesome-icon
                class="chevron-up"
                icon="chevron-up"
                @click="sortUpMethod(idx)"
                v-show="idx > 0"
              />
              <font-awesome-icon
                icon="chevron-down"
                class="chevron-down"
                @click="sortDownMethod(idx)"
                v-show="searchQuery.length - 1 > idx"
              />
            </div>
          </div>
        </div>
        <h6 v-if="!searchQuery.length">
          {{ noDataLabel }}
        </h6>
        <slot></slot>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";
import BaseCard from "@/components/Cards/BaseCard";

import { debounce } from "@/services/debounce";
import { sortUp } from "@/services/sorting";
import { sortDown } from "@/services/sorting";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { customExternalLink } from "@/assets/icons";
library.add(customExternalLink, faChevronUp, faChevronDown);

export default {
  name: "SearchableTableCard",
  components: {
    Button,
    BaseCard
  },
  props: {
    searchPlaceholder: {
      type: String,
      default: "",
    },
    headerLabel: {
      type: String,
      default: "",
    },
    headerBtn: {
      type: Object,
      default: () => {},
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
    noDataLabel: {
      type: String,
      default: 'No data'
    },
    emitSearch: {
			type: Boolean,
			default: false
		}
  },
  data() {
    return {
      search: "",
      collapsed: true,
      searchQuery: []
    };
  },
  mounted() {
    this.searchQuery = this.list;
    this.doSearch();
  },
  computed: {
    // searchQuery() {
    //   return this.list.filter((item) => {
    //     return item.label.match(this.search);
    //   });
    // },
  },
  watch: {
    list() {
      this.searchQuery = this.list;
      this.doSearch();
    }
  },
  methods: {
    doSearch() {
      this.searchQuery = this.list.filter((item) => {
        return item.label.match(this.search);
      });
    },
    sortDownMethod(index) {
      sortDown(this.searchQuery, index, this.$set);
      this.$emit('array-updated', this.searchQuery);
    },
    sortUpMethod(index) {
      sortUp(this.searchQuery, index, this.$set);
      this.$emit('array-updated', this.searchQuery);
    },
    searchUpdated(phrase) {
      debounce( () => {
				if (this.emitSearch) {
					this.$emit('searchInTableCard', phrase);
				} else {
					this.search = phrase;
          this.doSearch();
				}
			}, 500, `searchable-table-card-${this._uid}` );
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.searchable-table-card {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $light_gray;
    padding: 25px;

    .title {
      font-family: "Oswald", sans-serif;
    }
  }

  &__body {
    .custom-search-left {
      margin: 25px;
    }

    .tabel-list {
      max-width: 100%;
      overflow-x: auto;

      h6 {
        padding: 0px 25px 25px 25px;
        text-align: center;
        margin-bottom: 0px;
      }

      &::-webkit-scrollbar {
        height: $scrollbar_height;
      }

      &::-webkit-scrollbar-track {
        background: $light_gray;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $focus_color;
      }

      &__item {
        min-width: 250px;
        padding-right: 25px;

        &:not(:last-child) {
          .wrapper {
            border-bottom: 1px solid $light_gray;
          }
        }

        .wrapper {
          display: flex;
          align-items: center;
          min-height: 50px;
          padding-left: 25px;
          justify-content: space-between;
          padding: 15px;
        }

        .label {
          color: $dark_text;
          font-size: 0.875rem;
          text-overflow: ellipsis;
          overflow: hidden;
          padding-right: 15px;
        }

        .link {
          display: flex;
          flex: 0 0 20px;
          color: $light_text;
          margin-left: auto;
          margin-right: 15px;
        }

        .status {
          flex: 0 0 50px;
          color: $light_text;
          font-size: 0.875rem;
          text-align: center;
        }

        .sorting {
          flex: 0 0 10px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          color: $light_text;
          
          svg {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
