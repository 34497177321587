<template>
  <div class="sub-content content-padding">
    <TableOverview
      kind="module"
      :page-title="labels.submodules"
      :searchable="true"
      :settings="settings"
      :footer-buttons="footerButtons"
      :get-page="getSubModulesMt"
      :sort-up-fn="sortUp"
      :sort-down-fn="sortDown"
    >
      <template v-slot:optional-content>
        <div class="row mt-4">
          <div class="col-12 col-lg-6 mb-4 mb-lg-0">
            <SearchableTableCard
              :list="definitionList"
              :search-placeholder="labels.write_here_to_search"
              :header-label="labels.definitions"
              :searchable="true"
              :emit-search="true"
              @searchInTableCard="searchDefinitions"
            >
            <Pagination
              :total-rows="definitionsPagination.items.total"
              :per-menu="definitionsPagination.items.limit || 20"
              :current-pagination="definitionsPagination.pages.current"
              @paginationChanged="paginationChanged"
              :pages-on-screen="5"
            />
            </SearchableTableCard>
          </div>
          <div class="col-12 col-lg-6">
            <SearchableTableCard
              :list="cardList"
              :search-placeholder="labels.write_here_to_search"
              :header-label="labels.cards"
              :searchable="true"
              :sortable="true"
              :emit-search="true"
              @searchInTableCard="searchCards"
            >
            <Pagination
              :total-rows="cardsPagination.items.total"
              :per-menu="cardsPagination.items.limit || 20"
              :current-pagination="cardsPagination.pages.current"
              @paginationChanged="cardsPaginationChanged"
              :pages-on-screen="5"
            />
            </SearchableTableCard>
          </div>
        </div>
      </template>
    </TableOverview>
  </div>
</template>

<script>
import TableOverview from "@/views/Dashboard/Common/TableOverview/";
import SearchableTableCard from "@/components/Table/SearchableTableCard.vue";
import Pagination from "@/components/Helpers/Pagination";

const colors = require("/colors.config.json");
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SubContent",
  data() {
    return {
      loaded: {
        cards: false,
        definitions: false
      },
      definitionList: [],
      cardList: [],
      cardsPagination: {
        items: {
          begin: 0,
          end: 0,
          limit: 0,
          total: 0
        },
        pages: {
          current: 0,
          hasNext: false,
          hasPrev: false,
          next: 0,
          prev: 0,
          total: 0
        }
      },
      definitionsPagination: {
        items: {
          begin: 0,
          end: 0,
          limit: 0,
          total: 0
        },
        pages: {
          current: 0,
          hasNext: false,
          hasPrev: false,
          next: 0,
          prev: 0,
          total: 0
        }
      },
      cardsSearch: '',
      definitionSearch: ''
    };
  },
  components: {
    TableOverview,
    SearchableTableCard,
    Pagination
  },
  methods: {
    ...mapActions("_module", {
      getModules: "getList",
      sortSubModule: "sortSubModule",
    }),
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("card", {
      getCards: "getList",
    }),
    getCardsMt(page, kind) {
      if (kind == 0) this.loaded.cards = false;
      if (kind == 1) this.loaded.definitions = false;

      let data = { kind: kind, cardsMap: true };
      if (page) data.page = page;
      if (kind == 0 && this.$route.query.cardsPage) data.page = this.$route.query.cardsPage;
      if (kind == 1 && this.$route.query.definitionsPage) data.page = this.$route.query.definitionsPage; 
      if (kind == 0 && this.cardsSearch?.length) data.search = this.cardsSearch;
      if (kind == 1 && this.definitionSearch?.length) data.search = this.definitionSearch;

      // Reset old values
      if (kind == 0) this.cardList = [];
      if (kind == 1) this.definitionList = [];

      this.getCards(data).then((res) => {
        if (res.success) {
          let resultList = res.data.data;

          if (data.kind == 0) {
            this.cardsPagination.items = res.data.items;
            this.cardsPagination.pages = res.data.pages;
          } else {
            this.definitionsPagination.items = res.data.items;
            this.definitionsPagination.pages = res.data.pages;
          }

          if (resultList.length >= 1) {
            resultList.forEach((item) => {
              if (item.kind === 0) {
                this.cardList.push({
                  _id: item._id,
                  label: item.title,
                  actions: [
                    {
                      type: "icon",
                      icon: "custom-external-link",
                      link: { name: "edit-card", params: { id: item._id } }
                    },
                  ],
                });
              }

              if (item.kind === 1) {
                this.definitionList.push({
                  _id: item._id,
                  label: item.title,
                  actions: [
                    {
                      type: "icon",
                      icon: "custom-external-link",
                      link: {
                        name: "edit-definition",
                        params: { id: item._id },
                      }
                    },
                  ],
                });
              }
            });
          }
          
          if (kind == 0) this.loaded.cards = true;
          if (kind == 1) this.loaded.definitions = true;
        } else if (res.error) {
          this.addNotification({
            variant: "danger",
            msg: res.error,
            labels: this.labels,
          });
        }
      });
    },
    getSubModulesMt(page) {
      let data = {
        page,
        parent: this.$route.params.id,
      };

      this.getModules(data)
        .then((res) => {
          if (res.success) {
            let subModules = [];
            res.data.data.forEach((item) => {
              subModules.push({
                _id: item._id,
                label: item.title,
                actions: [
                  {
                    type: "icon",
                    icon: "custom-external-link",
                    action: () => {},
                  },
                ],
              });
            });

            this.tableList = subModules;
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    sortUp(obj, idx) {
      const id = obj.tableList[idx]._id;
      console.log("Sort up submodule with id:", id);

      this.sortSubModule({
        id: id,
        direction: "left",
      })
        .then((res) => {
          if (res.success) {
            let temp = obj.tableList[idx];
            obj.tableList[idx] = obj.tableList[idx - 1];
            obj.tableList[idx - 1] = temp;
            obj.updateModulesList(obj.tableList);
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    sortDown(obj, idx) {
      const id = obj.tableList[idx]._id;
      console.log("Sort down submodule with id:", id);

      this.sortSubModule({
        id: id,
        direction: "right",
      })
        .then((res) => {
          if (res.success) {
            let temp = obj.tableList[idx];
            obj.tableList[idx] = obj.tableList[idx + 1];
            obj.tableList[idx + 1] = temp;
            obj.updateModulesList(obj.tableList);
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    paginationChanged(page){
      this.$router.replace({ name: this.$route.name, params: this.$route.params, query: { ...this.$route.query, definitionsPage: page } })
    },
    cardsPaginationChanged(page){
      this.$router.replace({ name: this.$route.name, params: this.$route.params, query: { ...this.$route.query, cardsPage: page } })
    },
    searchCards(title) {
      this.cardsSearch = title;
    },
    searchDefinitions(title) {
      this.definitionSearch = title;
    }
  },
  watch: {
    '$route.query.definitionsPage'(newValue) {
      this.getCardsMt(newValue, 1);
    },
    '$route.query.cardsPage'(newValue) {
      this.getCardsMt(newValue, 0);
    },
    cardsSearch() {
      if (this.$route.query.cardsPage == 1) {
        this.getCardsMt(1, 0);
      } else {
        this.$router.replace({ name: this.$route.name, params: this.$route.params, query: { ...this.$route.query, cardsPage: 1 } });
      }
    },
    definitionSearch() {
      if (this.$route.query.definitionsPage == 1) {
        this.getCardsMt(1, 1);
      } else {
        this.$router.replace({ name: this.$route.name, params: this.$route.params, query: { ...this.$route.query, definitionsPage: 1 } });
      }
    }
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
    footerButtons() {
      return [];
    },
    settings() {
      return {
        header: [
          {
            headerLabel: "Name",
            headerColor: `${colors.gray_text}`,
            key: "title",
            computeFn: (input) => input,
            bodyColor: `${colors.dark}`,
            columnWidth: "1 0 auto",
            whiteSpace: "nowrap",
          },
        ],
        sortable: true,
        sortStyles: { flex: "0 0 50px", color: `${colors.gray_text}` },
        minTableWidth: "300px",
        actionsWidth: "0 0 100px",
        actionsHeaderLabel: "",
        actions: [
          {
            label: "Open",
            variant: "btn-purple",
            styles: { padding: "8px 15px", fontWeight: "700" },
            action: (i) => {
              this.$router.push({
                name: "module-editor",
                params: { id: i._id },
              });
              this.$router.go(0);
            },
          },
        ],
      };
    },
  },
  mounted() {
    this.getCardsMt(1, 0);
    this.getCardsMt(1, 1);
    this.getSubModulesMt(1);
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep {
    .page__pagination {
      margin-top: 15px;
    }
  }
</style>
